import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Line from '../../components/Line/Line';
import { Container, Row, Col } from 'react-bootstrap';
import c from 'classnames';
import showdown from 'showdown';
import * as styles from './about.module.scss';

const About = ({ about }) => {
  const converter = new showdown.Converter();
  const image = about.image;
  const content = about.content;

  return (
    <Container>
      <Row className={styles.section}>
        <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
          <h3 className={c(styles.aboutTitle, 'title')}>{about.title}</h3>
          <p className={styles.paragraph}>
            {' '}
            <div
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(about.paragraph)
              }}
            />
          </p>
          <Line />
        </Col>
        <Col
          className="text-center"
          xs={{ span: 12, order: 1 }}
          md={{ span: 6, order: 1 }}
        >
          <GatsbyImage
            image={getImage(image)}
            alt={about.imageAlt}
            className={c(styles.image, 'image')}
          />
        </Col>
      </Row>
      <Row className={styles.section2}>
        <Col md={7}>
          <h3 className={c(styles.content1, 'title')}>{content.title1}</h3>
          <p className={styles.paragraph}>{content.paragraph1}</p>
          <h3 className="title">{content.title2}</h3>
          <p className={styles.paragraph}>{content.paragraph2}</p>
        </Col>
        <Col md={5}>
          <h3 className={c(styles.content3, 'title')}>{content.title3}</h3>
          <p className={styles.paragraph}>{content.paragraph3}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
