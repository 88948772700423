import * as React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO/SEO';
import Header from '../../components/Header/Header';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import About from '../../components/About/About';
import * as styles from '../../components/Header/header.module.scss';
import { LineIcon } from '../../images/index';

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const seoData = {
    markdownRemark: {
      frontmatter: {
        en: data.markdownRemark.frontmatter.en.seo,
        hu: data.markdownRemark.frontmatter.hu.seo
      }
    }
  };
  const intl = useIntl();
  const langData = intl.locale === 'hu' ? frontmatter.hu : frontmatter.en;
  const crumbs = ['home', langData.heading];

  return (
    <Layout>
      <SEO seoData={seoData} />
      <Header>
        {langData.heading.split('\n\n').map((line, index) => (
          <h1 className={styles.headerTitle} key={index}>
            {line}
          </h1>
        ))}
        <LineIcon className={styles.lineIcon} />
      </Header>
      <BreadCrumbs crumbs={crumbs} />
      <About about={langData} />
    </Layout>
  );
};
export default AboutPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(fileAbsolutePath: { regex: "/about.md/" }) {
      frontmatter {
        en {
          heading
          title
          paragraph
          image {
            childImageSharp {
              gatsbyImageData(height: 609)
            }
          }
          imageAlt
          content {
            title1
            paragraph1
            title2
            paragraph2
            title3
            paragraph3
          }
          seo {
            seoTagList {
              seoTagKey
              seoTagValue
            }
            siteTitle
            siteDescription
          }
        }
        hu {
          heading
          title
          paragraph
          image {
            childImageSharp {
              gatsbyImageData(height: 609)
            }
          }
          imageAlt
          content {
            title1
            paragraph1
            title2
            paragraph2
            title3
            paragraph3
          }
          seo {
            seoTagList {
              seoTagKey
              seoTagValue
            }
            siteTitle
            siteDescription
          }
        }
      }
    }
  }
`;
